import sky from './assets/img/background-layers/sky.png';
import ground1 from './assets/img/background-layers/ground_1.png';
import ground2 from './assets/img/background-layers/ground_2.png';
import ground3 from './assets/img/background-layers/ground_3.png';
import rocks from './assets/img/background-layers/rocks.png';
import clouds1 from './assets/img/background-layers/clouds_1.png';
import clouds2 from './assets/img/background-layers/clouds_2.png';
import logo from './assets/img/logo.svg';
import './App.css';
import {ParallaxBanner, ParallaxProvider} from 'react-scroll-parallax';

function App() {
  return (
    <div className="App">
        <ParallaxProvider>
            <ParallaxBanner
                id="parallax-banner"
                layers={[
                    { image: sky, speed: -40, },
                    {
                        speed: -50,
                        children: (
                            <div className="custom-parallax-container" style={{height: `${window.innerHeight}px`}}>
                                <div id="moon" />
                            </div>
                        ),
                    },
                    { image: clouds1, speed: -35 },
                    { image: rocks, speed: -20 },
                    { image: clouds2, speed: -25 },
                    { image: ground1, speed: -20 },
                    { image: ground2, speed: -15 },
                    { image: ground3, speed: -10 },
                    {
                        opacity: [1, 0],
                        shouldAlwaysCompleteAnimation: true,
                        children: (
                            <div className="custom-parallax-container bottom" style={{height: `${window.innerHeight}px`}}>
                                <div className="mouse_scroll">
                                    <div className="mouse">
                                        <div className="wheel"></div>
                                    </div>
                                    <div>
                                        <span className="m_scroll_arrows unu"></span>
                                        <span className="m_scroll_arrows doi"></span>
                                        <span className="m_scroll_arrows trei"></span>
                                    </div>
                                </div>
                            </div>
                        ),
                    },
                    {
                        speed: -22,
                        children: (
                            <div className="custom-parallax-container" style={{height: `${window.innerHeight}px`}}>
                                <img id="logo" src={logo} alt="Javascript" />
                                <h1>La tribu<span>_</span></h1>
                            </div>
                        ),
                    },
                ]}
                style={{
                    width: '100%',
                    height: `${window.innerHeight + 200}px`
                }}
            />
        </ParallaxProvider>

        <section className="container">
            <h2>Rejoins la tribu Fullstack JS</h2>
            <p>
                Nous considérons qu’une équipe dans laquelle chaque développeur est capable d’intervenir sur
                <strong> l’intégralité de la stack technique</strong> est plus efficace et qu’utiliser un <strong>langage unique</strong> pour le <strong>front-end</strong>{" "}
                et le <strong>back-end</strong> permet d’avoir un environnement cohérent <strong>évitant le context-switching</strong>.
            </p>
            <p>Remplis le formulaire en cliquant sur le lien en dessous (session octo obligatoire)</p>
            <div className="centeredText">
                <a className="button" href="https://forms.gle/gPFFY8VdVLrhPuut7">Inscris toi</a>
            </div>
        </section>
        <footer>
            <a href="https://le-dev.com">
                <svg data-name="le-dev-logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240.51 226.73" width="1.2em"
                     height="1.2em">
                    <path fill="currentColor"
    d="M144.24 45.21l96.27 181.52h-47.73l-.01-.02-30.89-58.23L120.26 90l23.98-44.79z"/>
                    <path fill="currentColor" d="M161.88 168.48l30.89 58.23-91.27-32.93 20.53-39.68 39.85 14.38z"/>
                    <path fill="currentColor" d="M120.26 0l23.98 45.21L120.26 90 47.74 226.73H0L120.26 0z"/>
                </svg>
                le-dev.com
            </a>
            <a href="https://www.octo.com/">
                <svg data-name="le-dev-logo" xmlns="http://www.w3.org/2000/svg" viewBox="-33 34 33 33" width="1.2em"
                     height="1.2em">
                    <g id="Page-1">
                        <g id="Search" transform="translate(-60.000000, -58.000000)">
                            <g id="Logo-Tête" transform="translate(60.000000, 58.000000)">
                                <g id="Group-3" transform="translate(28.000000, 0.000000)">
                                    <g id="Clip-2">
                                    </g>
                                    <path fill="currentColor" id="Fill-1" className="st0"
                                          d="M-28.8,34.9c0.9,0.9,0.9,2.5,0,3.4s-2.5,0.9-3.4,0c-0.9-0.9-0.9-2.5,0-3.4      C-31.3,33.9-29.7,33.9-28.8,34.9"/>
                                </g>
                                <path fill="currentColor" id="Fill-4" className="st0"
                                      d="M-10.6,44.7c1,1,1,2.6,0,3.5c-1,1-2.6,1-3.5,0c-1-1-1-2.6,0-3.5C-13.2,43.8-11.6,43.8-10.6,44.7     "/>
                                <g id="Group-8">
                                    <g id="Clip-7">
                                    </g>
                                    <path fill="currentColor" id="Fill-6" className="st0"
                                          d="M-16.5,60.1c-5.2,0-9.5-4.3-9.5-9.5s4.3-9.5,9.5-9.5S-7,45.4-7,50.6S-11.3,60.1-16.5,60.1       M-16.5,34.1c-9,0-16.4,7.4-16.4,16.4c0,9.1,7.4,16.4,16.4,16.4s16.4-7.4,16.4-16.4C-0.1,41.5-7.5,34.1-16.5,34.1"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
                octo.com
            </a>

        </footer>
    </div>
  );
}

export default App;
